import React from 'react';

type Props = {}

export const PageLoading = (props: Props) => (
  <div className="loading-container">
		<div className="first-indicator" />
		<div className="second-indicator" />
	</div>
);


export default PageLoading;
