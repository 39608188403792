import skills from "../../data/skills";
import educations from "../../data/education";
import projects from "../../data/projects";
import workExps from "../../data/workExp";
import versions from "../../data/versions";

// 1. get api with global config
//    - able to add additional layer
// 2. adjust header in api
// ----
// implement apis
// implement apis.get post put delete
// implement getXXXXX, updateXXXX
// ---
// tasks:
// 1. class with for method
// 2. 
// 3. defined XXX interface

// interface HttpAction {
//   (url: string, config: string): Promise
// }

// class APIFetcher {
//   constructor() {
//     this.header = {}

//   }
//   get: HttpAction = (url, config) => {
    
//   }
//   post: HttpAction = (url, config) => {

//   }
//   put: HttpAction = (url, config) => {

//   }
//   delete: HttpAction = (url, config) => {

//   }
// }

interface Fetcher {
  (): Promise<Array<any>>
}

interface FetchSync {
  (): Array<any>
}

export const getSkills: Fetcher = () => {
  return new Promise((resolve, reject) => {
    resolve(skills);
  });
};

export const getEducations: Fetcher = () => {
  return new Promise((resolve, reject) => {
    resolve(educations);
  });
};

export const getProjects: Fetcher = () => {
  return new Promise((resolve, reject) => {
    resolve(projects);
  });
};

export const getWorkExps: Fetcher = () => {
  return new Promise((resolve, reject) => {
    resolve(workExps);
  });
};

export const getVersions: Fetcher = () => {
  return new Promise((resolve, reject) => {
    resolve(versions);
  });
};
export const getSkillsSync: FetchSync = () => skills;

export const getEducationsSync: FetchSync = () => educations;

export const getProjectsSync: FetchSync = () => projects;

export const getWorkExpsSync: FetchSync = () => workExps;

export const getVersionsSync: FetchSync = () => versions;
