// score:
// 1: I have idea about it.
// 2: I have basic understanding of these skills, if necessary, I can pick them up quickly.
// 3: I knowledge of these skills and actually implement them in some projects, but not frequently used on work. 
// 4: I used to heavily use it and have some study in depth.
// 5: I have a solid understanding of this skill on the whole and I am able to use it suitably under different context.

const skills: Array<Skill> = [
  {
    id: 'gcp',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'google-cloud-platform',
    name: 'Cloud',
    description: ['!skill.gcp.desc1'],
    offset: 2,
    score: 2
  },
  // {
  //   id: 'k8s',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'web',
  //       type: 0
  //     }
  //   ],
  //   slug: 'k8s',
  //   name: 'Kubernetes',
  //   description: ['!skill.k8s.desc1', '!skill.k8s.desc2'],
  //   offset: 4,
  //   score: 2
  // },
  {
    id: 'js',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'javascript',
    name: 'Javascript',
    description: ['!skill.javascript.desc1'],
    offset: 1,
    score: 3
  },
  {
    id: 'ts',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'typescript',
    name: 'Typescript',
    description: ['!skill.typescript.desc1'],
    offset: 1,
    score: 2.5
  },
  {
    id: 'react',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'react',
    name: 'React',
    description: ['!skill.react.desc1'],
    offset: 0,
    score: 3
  },
  // {
  //   id: 'react-native',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'mobile',
  //       type: 0
  //     },
  //     {
  //       name: 'web',
  //       type: 1
  //     },
  //   ],
  //   slug: 'react-native',
  //   name: 'React Native',
  //   description: ['!skill.react-native.desc1'],
  //   offset: 1,
  //   score: 2.5
  // },
  {
    id: 'jquery',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'jquery',
    name: 'jQuery',
    description: ['!skill.jquery.desc1'],
    offset: 1,
    score: 2.5
  },
  {
    id: 'styling',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'styling',
    name: 'CSS',
    description: ['!skill.styling.desc1', '!skill.styling.desc2'],
    offset: 0,
    score: 3
  },
  {
    id: 'html',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'html',
    name: 'HTML',
    description: ['!skill.html.desc1'],
    offset: 4,
    score: 3
  },
  {
    id: 'd3',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'd3',
    name: 'D3',
    description: ['!skill.d3.desc1'],
    offset: 1,
    score: 2.5
  },
  // {
  //   id: 'canvas',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'web',
  //       type: 0
  //     }
  //   ],
  //   slug: 'canvas',
  //   name: 'Canvas',
  //   description: ['!skill.canvas.desc1'],
  //   offset: 1,
  //   score: 1.5
  // },
  // {
  //   id: 'vue',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'web',
  //       type: 0
  //     }
  //   ],
  //   slug: 'vue',
  //   name: 'Vue',
  //   description: ['!skill.vue.desc1'],
  // offset: 1,
  //   score: 4
  // },
  // {
  //   id: 'angular',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'web',
  //       type: 0
  //     }
  //   ],
  //   slug: 'angular',
  //   name: 'Angular',
  //   description: ['!skill.angular.desc1'],
  // offset: 1,
  //   score: 4
  // },
  {
    id: 'graphql',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'graphql',
    name: 'GraphQL',
    description: ['!skill.graphql.desc1'],
    offset: 5.5,
    score: 2.5
  },
  {
    id: 'nodejs',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'nodejs',
    name: 'NodeJS',
    description: ['!skill.nodejs.desc1'],
    offset: 3,
    score: 2.5
  },
  {
    id: 'express',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'express',
    name: 'ExpressJS',
    description: ['!skill.express.desc1'],
    offset: 3,
    score: 2
  },
  {
    id: 'nestjs',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'nestjs',
    name: 'NestJS',
    description: [''],
    offset: 3,
    score: 2
  },
  // {
  //   id: 'Mongodb',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'web',
  //       type: 0
  //     }
  //   ],
  //   slug: 'Mongodb',
  //   name: 'Mongodb',
  //   description: ['!skill.Mongodb.desc1'],
  //   offset: 1,
  //   score: 2
  // },
  // {
  //   id: 'sql',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'web',
  //       type: 0
  //     }
  //   ],
  //   slug: 'sql',
  //   name: 'SQL',
  //   description: ['!skill.sql.desc1'],
  // offset: 1,
  //   score: 4
  // },
  {
    id: 'docker',
    logo: '',
    tags: [
      {
        name: 'web',
        type: 0
      }
    ],
    slug: 'docker',
    name: 'Docker',
    description: ['!skill.docker.desc1'],
    offset: 1,
    score: 2
  },
  // {
  //   id: 'python',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'general',
  //       type: 0
  //     }
  //   ],
  //   slug: 'python',
  //   name: 'python',
  //   description: ['!skill.python.desc1'],
  // offset: 1,
  //   score: 4
  // },
  {
    id: 'alg',
    logo: '',
    tags: [
      {
        name: 'general',
        type: 0
      }
    ],
    slug: 'algorithm',
    name: 'Algorithm',
    description: ['!skill.algorithm.desc1'],
    offset: 1,
    score: 2.5
  },
  {
    id: 'data-structure',
    logo: '',
    tags: [
      {
        name: 'general',
        type: 0
      }
    ],
    slug: 'data-structure',
    name: 'Data Structure',
    description: ['!skill.data-structure.desc1'],
    offset: 2,
    score: 2.5
  },
  // {
  //   id: 'sql',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'general',
  //       type: 0
  //     }
  //   ],
  //   slug: 'mysql',
  //   name: 'SQL',
  //   description: ['!skill.sql.desc1'],
  //   offset: 2,
  //   score: 2.5
  // },
  // {
  //   id: 'web-knowledge',
  //   logo: '',
  //   tags: [
  //     {
  //       name: 'general',
  //       type: 0
  //     }
  //   ],
  //   slug: 'web-knowledge',
  //   name: 'Web Knowledge',
  //   description: ['!skill.web-knowledge.desc1'],
  // offset: 1,
  //   score: 4
  // },
];

export default skills;
