const educations = [
  {
    id: '0',
    slug: 'ncu',
    startDate: '2008-09',
    endDate: '`2008-09',
    school: '!edu.ncu.name',
    location: '!common.taipei',
    title: '!edu.ncu.title',
    link: '!edu.ncu.link'
  },
];

export default educations;
