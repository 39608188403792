import { PageSizing } from './../../../style/mixins';
import styled from 'styled-components';
import { sizes } from '../../../style/vars';

const PageContainer = styled.main`
  padding: 30px 0;
  @media screen and (max-width: ${sizes.screen.s}px) {
    padding: 15px 0;
  }
  ${PageSizing}
`;

export default PageContainer;
