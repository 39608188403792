import { TagType } from "../typing/enum";

const workExps = [
  {
    id: 'string2',
    slug: 'binance',
    title: '!common.f2e',
    location: 'Taipei Remote',
    companyName: 'Binance',
    startDate: '2021-12',
    endDate: `${new Date().getFullYear()}-${new Date().getMonth()+1}`,
    // endDate: `2021-10`,
    isCurrentWorking: true,
    headDescription: 'Develop internal tools and maintain multiple projects on binance.com.',
    description: [
      'Develop a fullstack internal i18n tools by NestJS.',
      'Develop features in A/B test to boost registration rate.',
      'Develop compliance features to meet regulation in multiple countries.',
      'Develop web application with charts and markets information.',
    ],
    tags: [
      {
        name: 'react',
        type: TagType.Skill
      }
    ],
    link: '!work.binance.link'
  },
  {
    id: 'string2',
    slug: 'tiktok',
    title: '!common.f2e',
    location: '!common.singapore',
    companyName: 'TikTok',
    startDate: '2021-04',
    // endDate: `${new Date().getFullYear()}-${new Date().getMonth()+1}`,
    endDate: `2021-10`,
    isCurrentWorking: false,
    headDescription: '!work.tiktok.headDesc',
    description: [
      '!work.tiktok.desc1',
      '!work.tiktok.desc2',
    ],
    tags: [
      {
        name: 'react',
        type: TagType.Skill
      }
    ],
    link: '!work.tiktok.link'
  },
  {
    id: 'string',
    slug: 'line',
    title: '!common.f2e',
    location: '!common.taipei',
    companyName: 'LINE Corp.',
    startDate: '2020-01',
    endDate: '2021-03',
    headDescription: '!work.line.headDesc',
    description: [
      // '!work.line.desc1',
      '!work.line.desc2',
      '!work.line.desc4',
      '!work.line.desc5',
      '!work.line.desc3',
    ],
    tags: [
      {
        name: 'react',
        type: TagType.Skill
      }
    ],
    link: '!work.line.link'
  },
  {
    id: 'string',
    slug: 'm17',
    title: '!common.f2e',
    location: '!common.taipei',
    companyName: '17 LIVE',
    startDate: '2019-06',
    endDate: '2020-01',
    headDescription: '!work.m17.headDesc',
    description: [
      // '!work.m17.desc1',
      // '!work.m17.desc2',
      // '!work.m17.desc3',
    ],
    tags: [
      {
        name: 'react',
        type: TagType.Skill
      }
    ],
    link: 'https://about.17.live/'



  },
  {
    id: 'string',
    slug: 'tradevalley',
    title: '!common.f2e',
    location: '!common.taipei',
    companyName: 'TradingValley',
    startDate: '2017-04',
    endDate: '2019-06',
    headDescription: '!work.tv.headDesc',
    description: [
      // '!work.tv.desc1',
      // '!work.tv.desc2',
      // '!work.tv.desc3',
      // '!work.tv.desc4',
      // '!work.tv.desc5',
      // '!work.tv.desc6',
    ],
    tags: [
      {
        name: 'react',
        type: TagType.Skill
      }
    ],
    link: '!work.tv.link'
  },
  {
    id: 'string',
    slug: 'awoo',
    title: '!common.f2e',
    location: '!common.taipei',
    companyName: '!work.awoo.companyName',
    startDate: '2015-11',
    endDate: '2017-04',
    // headDescription: '!work.awoo.headDesc',
    description: [
      // '!work.awoo.desc1',
      // '!work.awoo.desc2',
    ],
    tags: [
      {
        name: 'react',
        type: TagType.Skill
      }
    ],
    link: '!work.awoo.link'
  },
];

export default workExps;
