import { createGlobalStyle } from 'styled-components';
import resetCSS from './reset';
import { BlockContainer, ItemWrap, ItemTitle, ItemSubtitle, ItemTitleDecorate } from './../components/common/BlockInfo/index';
import { sizes, consumeTheme } from './vars';
import rcSwitch from './rc-switch';
import Tag from '../components/common/Tag';


export const GlobalStyle = createGlobalStyle`
  ${resetCSS}
  .show-print {
    display: none;
  }
  .fwb {
    font-style: italic;
    font-weight: bold;
  }
  @media print {
    body {
      line-height: 1.3;
      font-size: 14px;
      * {
        color: #000 !important;
        text-decoration: none;
      }
    }
    .hide-print {
      display: none !important;
    }
    .show-print {
      display: block !important;
    }
    .show-print-inline {
      display: inline !important;
    }
    .hide-awoo-when-print:nth-child(5) {
      display: none;
    }
    ${BlockContainer} {
      margin: 0;
    }
    ${ItemWrap} {
      margin-bottom: 8px
    }
    ${Tag} {
      letter-spacing: 0 !important;
      font-size: 14px;
      padding: 0;
      margin-right: 8px !important;
      margin-bottom: 4px !important;
      font-style: italic;
    }
    ${ItemTitle} {
      font-size: 16px;
      margin-bottom: 1px;
    }
    ${ItemSubtitle} {
      font-size: 14px;
    }
    ${ItemTitleDecorate} {
      font-size: 14px;
    }
  }

  .shake {
    display: inline-block;
    transform-origin: center center;
  }
  .shake.freez {
    animation-name: shake-base;
    animation-duration: 5s;
    animation-iteration-count: 5;
    animation-timing-function: ease-in-out;
  }
  @keyframes shake-base {
  
  60% {
    transform: translate(-0.5px, 1.5px) rotate(-0.5deg);
  }
  62% {
    transform: translate(1.5px, -0.5px) rotate(1.5deg);
  }
  64% {
    transform: translate(-0.5px, -0.5px) rotate(0.5deg);
  }
  66% {
    transform: translate(1.5px, 1.5px) rotate(1.5deg);
  }
  68% {
    transform: translate(1.5px, -1.5px) rotate(-0.5deg);
  }
  70% {
    transform: translate(-0.5px, 1.5px) rotate(-0.5deg);
  }
  72% {
    transform: translate(-1.5px, -1.5px) rotate(1.5deg);
  }
  74% {
    transform: translate(-0.5px, 0.5px) rotate(0.5deg);
  }
  76% {
    transform: translate(2.5px, 0.5px) rotate(0.5deg);
  }
  78% {
    transform: translate(0.5px, -0.5px) rotate(1.5deg);
  }
  80% {
    transform: translate(2.5px, 0.5px) rotate(-0.5deg);
  }
  82% {
    transform: translate(1.5px, 1.5px) rotate(0.5deg);
  }
  84% {
    transform: translate(0.5px, -1.5px) rotate(0.5deg);
  }
  86% {
    transform: translate(-1.5px, 0.5px) rotate(1.5deg);
  }
  88% {
    transform: translate(-0.5px, -0.5px) rotate(-0.5deg);
  }
  90% {
    transform: translate(2.5px, 0.5px) rotate(0.5deg);
  }
  92% {
    transform: translate(1.5px, 0.5px) rotate(-0.5deg);
  }
  94% {
    transform: translate(0.5px, 1.5px) rotate(0.5deg);
  }
  96% {
    transform: translate(1.5px, -0.5px) rotate(0.5deg);
  }
  98% {
    transform: translate(-0.5px, 0.5px) rotate(0.5deg);
  }
}
  body {
    color: ${consumeTheme('textMain')};
    background-color: ${consumeTheme('body')};
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Ubuntu', sans-serif;
    /* font-family: 'Bonbon', sans-serif; */
    /* font-family: 'Literata', sans-serif; */
    /* font-family: 'Quicksand', sans-serif; */
    /* font-family: 'Indie Flower', sans-serif; */
    /* font-family: 'Rokkitt', sans-serif; */
    /* font-family: 'Kalam', sans-serif; */
    /* font-family: 'Josefin Slab', sans-serif; */
    /* Gotham SSm A,Gotham SSm B,PingFang TC,Microsoft JhengHei,PMingLiU,sans-serif */
  }
  h1, h2, h3, h4, h5, h6, p {
    color: ${consumeTheme('textMain')};
  }
  a {
    color: ${consumeTheme('main')};
  }
  .hl { /* highlight */
    /* font-weight: bold; */
    /* color: #222; */
  }
  .il-link {
    color: ${consumeTheme('textMain')};
  }

  .hide-s {
    @media screen and (max-width: ${sizes.screen.s}px) {
      display: none;
    }
  }
  ${rcSwitch}
  .loading-container {
  background-color: rgba(255,255,255,.2);
  height: 4px;
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 30px;
  left: 0;
  z-index: 99999999;
  .first-indicator, .second-indicator {
    background-color: #55c7c3;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: translate3d(0, 0, 0) scaleX(0);
    transform: scaleX(0);
    will-change: transform;
  }
  .first-indicator {
    animation: first-indicator 2000ms linear infinite;
  }
  .second-indicator {
    animation: second-indicator 2000ms linear infinite;

  }
}
@keyframes first-indicator {
  0% {
    transform: translateX(0vw) scaleX(0);
  }
  25% {
      transform: translateX(0vw) scaleX(0.5);
  }
  50% {
      transform: translateX(25vw) scaleX(0.75);
  }
  75% {
      transform: translateX(100vw) scaleX(0);
  }
  100% {
      transform: translateX(100vw) scaleX(0);
  }
}
@keyframes second-indicator {
  0% {
    transform: translateX(0vw) scaleX(0);
  }
  60% {
      transform: translateX(0vw) scaleX(0);
  }
  80% {
      transform: translateX(0vw) scaleX(0.6);
  }
  100% {
      transform: translateX(100vw) scaleX(0.1);
  }
}

`;
