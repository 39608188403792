import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoon,
  faSun,
} from '@fortawesome/free-solid-svg-icons';
import Switch from 'rc-switch';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { PathParamsType, toUrl, fromUrl } from '../LanguageProvider';
import { consumeTheme } from '../../style/vars';
import { AppStateContext, AppActionType } from '../App';
import { PageSizing } from '../../style/mixins';

const HEIGHT = 30;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${HEIGHT}px;
  @media print {
    display: none;
  }
  z-index: 5;
`;

const SizingWrap = styled.div`
  width: 100%;
  position: fixed;
  background-color: ${consumeTheme('headerBg')};
  top : 0;
  left: 0;
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: ${HEIGHT}px;
  ${PageSizing}
`;

interface ItemProps {
  isActive?: boolean
}

const Item = styled.li<ItemProps>`
  color: ${consumeTheme('headerText')};
  margin-right: 15px;
  font-size: 14px;
  cursor: pointer;
  font-weight: ${({ isActive }: ItemProps) => isActive ? 'bold' : 'normal'}
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

type Props = {
} &  GeneralComponent & RouteComponentProps<PathParamsType>;

const FunctionBar = ({
  containerStyle,
  location,
  history,
  match
}: Props) => {
  const {
    state: { isDarkMode },
    dispatcher
  } = useContext(AppStateContext);
  // const currentLang = getCurrentLangByLocation();
  return (
    <Container style={containerStyle}>
      <SizingWrap>
        <List>
          {/* <Link to="/version" style={{ color: '#fff' }}> */}
          {/* <Version>
            v2.0.0
          </Version> */}
          {/* <NavLink to={toUrl('/')} style={{ textDecoration: 'none' }}>
            <Item isActive={ fromUrl(window.location.pathname) === '/' }>
              <FormattedMessage
                id="!common.nav.resume"
              />
            </Item>
          </NavLink> */}
          <NavLink to={toUrl('/philosophy')} style={{ textDecoration: 'none' }}>
            <Item isActive={ fromUrl(window.location.pathname) === '/philosophy' }>
              <FormattedMessage
                id="!common.nav.philosophy"
              />
            </Item>
          </NavLink>
          <a href="https://medium.com/kenneth1003" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Item>
              Medium
            </Item>
          </a>
          {/* <NavLink to={toUrl('/skills')} style={{ textDecoration: 'none' }}>
            <Item isActive={ fromUrl(window.location.pathname) === '/skills' }>
              <FormattedMessage
                id="!common.nav.skills"
              />
            </Item>
          </NavLink>
          <NavLink to={toUrl('/projects')} style={{ textDecoration: 'none' }}>
            <Item isActive={ fromUrl(window.location.pathname) === '/projects' }>
              <FormattedMessage
                id="!common.nav.projects"
              />
            </Item>
          </NavLink>
          <NavLink to={toUrl('/work-experience')} style={{ textDecoration: 'none' }}>
            <Item isActive={ fromUrl(window.location.pathname) === '/work-experience' }>
              <FormattedMessage
                id="!common.nav.workExps"
              />
            </Item>
          </NavLink> */}
          
          {
            // location.pathname !== '/version'
            // &&
            // <>
            //   <Item>
            //     |
            //   </Item>
            //   {
            //     currentLang !== 'en'
            //     && <Item onClick={() => {
            //       history.push(window.location.pathname.replace('/zh', ''));
            //     }}>
            //       English
            //     </Item>
            //   }
              
            //   {
            //     currentLang !== 'zh'
            //     && <Item onClick={() => {
            //       history.push('/zh' + window.location.pathname);
            //     }}>
            //     繁體中文
            //   </Item>
            //   }
            // </>
          }
          <Item>
            <Switch
              onClick={() => dispatcher({ type: AppActionType.TOGGLE_DARK_MODE })}
              checked={ isDarkMode }
              checkedChildren={
                <FontAwesomeIcon
                  icon={ faSun }
                  style={{ fontSize: 12 }}
                />
              }
              unCheckedChildren={
                <FontAwesomeIcon
                icon={ faMoon }
                style={{ fontSize: 12 }}
                />
              }
            />
          </Item>
        </List>
      </SizingWrap>
    </Container>
  );
};


export default withRouter(FunctionBar);
