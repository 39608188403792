import { TagType } from "../typing/enum";

const projects = [
  {
    id: '6',
    slug: 'entry-js',
    title: 'Fastboard',
    location: '!project.easystate.location',
    productName: '!project.easystate.productName',
    companyName: '!project.easystate.companyName',
    sideNote: '!project.easystate.sideNote',
    startDate: 'From 2022',
    endDate: '2022.12',
    description: [
      // '!project.entryjs.desc1'
    ],
    productDesc: 'Real time writeboard app that allows users draw on their smart phone and display on any browser.',
    remark: '!project.easystate.remark',
    lineOfCode: 1000, // ~10,000
    images: ['image'],
    thumbnails: 'string',
    link: 'https://apps.apple.com/us/app/fastboard-online-whiteboard/id6444909854',
    linkCV: 'https://apps.apple.com/us/app/fastboard-online-whiteboard/id6444909854',
    // status: 'pending',
    tags: [
      {
        name: 'React',
        type: TagType.Skill
      },
      {
        name: 'NestJS',
        type: TagType.Skill
      },
      {
        name: 'Flutter',
        type: TagType.Skill
      },
      {
        name: 'EC2',
        type: TagType.Skill
      },
      {
        name: 'S3',
        type: TagType.Skill
      },
    ],
  },
  {
    id: '5',
    slug: 'entry-js',
    title: '!project.easystate.title',
    location: '!project.easystate.location',
    productName: '!project.easystate.productName',
    companyName: '!project.easystate.companyName',
    sideNote: '!project.easystate.sideNote',
    startDate: 'From 2021',
    endDate: '2019.06',
    description: [
      // '!project.entryjs.desc1'
    ],
    productDesc: '!project.easystate.productDesc',
    remark: '!project.easystate.remark',
    lineOfCode: 1000, // ~10,000
    images: ['image'],
    thumbnails: 'string',
    link: 'https://playground.kenneth.pro/easy-state?utm_source=profile',
    linkCV: 'https://playground.kenneth.pro/easy-state',
    status: 'pending',
    tags: [
      // {
      //   name: 'Side project',
      //   type: TagType.Skill
      // },
      // {
      //   name: 'Open source',
      //   type: TagType.Skill
      // },
      {
        name: 'React',
        type: TagType.Skill
      },
      {
        name: 'Redux',
        type: TagType.Skill
      },
      {
        name: 'Typescript',
        type: TagType.Skill
      },
    ],
  },
  // {
  //   id: '1',
  //   slug: 'growin',
  //   title: '!project.growin.title',
  //   location: '!project.growin.location',
  //   productName: '!project.growin.productName',
  //   companyName: '!project.growin.companyName',
  //   sideNote: '!project.growin.sideNote',
  //   startDate: '2017.04',
  //   endDate: '2019.06',
  //   description: [
  //     '!project.growin.desc1',
  //     '!project.growin.desc2',
  //   ],
  //   productDesc: '!project.growin.productDesc',
  //   remark: '!project.growin.remark',
  //   lineOfCode: 10000, // ~10,000
  //   images: ['image'],
  //   thumbnails: 'string',
  //   link: 'https://apps.apple.com/tw/app/growin/id1462382649',
  //   status: 'pending',
  //   tags: [
  //     {
  //       name: 'React Native',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'Redux',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'Firebase',
  //       type: TagType.Skill
  //     },
  //   ],
  // },
  // {
  //   id: '0',
  //   slug: 'tv-web1',
  //   title: '!project.tv.title',
  //   location: '!project.tv.location',
  //   productName: '!project.tv.productName',
  //   companyName: '!project.tv.companyName',
  //   sideNote: '!project.tv.sideNote',
  //   startDate: '2017.04',
  //   endDate: '2019.06',
  //   description: [
  //     '!project.tv.desc1',
  //     '!project.tv.desc2',
  //   ],
  //   productDesc: '!project.tv.productDesc',
  //   remark: '!project.tv.remark',
  //   lineOfCode: 10000, // ~10,000
  //   images: ['image'],
  //   thumbnails: 'string',
  //   link: 'https://www.tradingvalley.com/app/product-list/',
  //   status: 'pending',
  //   tags: [
  //     {
  //       name: 'React',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'Redux',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'Sass',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'Webpack',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'D3',
  //       type: TagType.Skill
  //     },
  //   ],
  // },
  {
    id: '4',
    slug: 'visify',
    title: '!project.visify.title',
    location: '!project.visify.location',
    productName: '!project.visify.productName',
    companyName: '!project.visify.companyName',
    sideNote: '!project.visify.sideNote',
    startDate: 'From 2020',
    endDate: '2019.06',
    description: [
      // '!project.render-ctrl.desc1'
    ],
    productDesc: '!project.visify.productDesc',
    remark: '!project.visify.remark',
    lineOfCode: 10000, // ~10,000
    images: ['image'],
    thumbnails: 'string',
    link: 'https://www.visify.life/?utm_source=profile',
    linkCV: 'https://www.visify.life/',
    status: 'pending',
    tags: [
      // {
      //   name: 'Side project',
      //   type: TagType.Skill
      // },
      {
        name: 'React',
        type: TagType.Skill
      },
      {
        name: 'D3',
        type: TagType.Skill
      },
      {
        name: 'Firebase',
        type: TagType.Skill
      },
    ],
  },
  {
    id: '4',
    slug: 'react-render-ctrl',
    title: '!project.render-ctrl.title',
    location: '!project.render-ctrl.location',
    productName: '!project.render-ctrl.productName',
    companyName: '!project.render-ctrl.companyName',
    sideNote: '!project.render-ctrl.sideNote',
    startDate: 'From 2018',
    endDate: '2019.06',
    description: [
      // '!project.render-ctrl.desc1'
    ],
    productDesc: '!project.render-ctrl.productDesc',
    remark: '!project.render-ctrl.remark',
    lineOfCode: 10000, // ~10,000
    images: ['image'],
    thumbnails: 'string',
    link: 'https://github.com/kenneth1003/react-render-ctrl',
    linkCV: 'https://github.com/kenneth1003/react-render-ctrl',
    status: 'pending',
    tags: [
      // {
      //   name: 'Side project',
      //   type: TagType.Skill
      // },
      {
        name: 'Open source',
        type: TagType.Skill
      },
      {
        name: 'Npm',
        type: TagType.Skill
      },
      {
        name: 'React',
        type: TagType.Skill
      },
    ],
  },
  // {
  //   id: '2',
  //   slug: 'tai-sheng',
  //   title: '!project.tai-sheng.title',
  //   location: '!project.tai-sheng.location',
  //   productName: '!project.tai-sheng.productName',
  //   companyName: '!project.tai-sheng.companyName',
  //   sideNote: '!project.tai-sheng.sideNote',
  //   startDate: '2017.04',
  //   endDate: '2019.06',
  //   description: [
  //     '!project.tai-sheng.desc1'
  //   ],
  //   productDesc: '!project.tai-sheng.productDesc',
  //   remark: '!project.tai-sheng.remark',
  //   lineOfCode: 10000, // ~10,000
  //   images: ['image'],
  //   thumbnails: 'string',
  //   link: '',
  //   status: 'pending',
  //   tags: [
  //     {
  //       name: 'React',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'GraphQL',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'Styled-components',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'ExpressJS',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'MongoDB',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'Circleci',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'KeystoneJS',
  //       type: TagType.Skill
  //     },
  //   ],
  // },
  // {
  //   id: '5',
  //   slug: 'entry-js',
  //   title: '!project.entryjs.title',
  //   location: '!project.entryjs.location',
  //   productName: '!project.entryjs.productName',
  //   companyName: '!project.entryjs.companyName',
  //   sideNote: '!project.entryjs.sideNote',
  //   startDate: '2017.04',
  //   endDate: '2019.06',
  //   description: [
  //     // '!project.entryjs.desc1'
  //   ],
  //   productDesc: '!project.entryjs.productDesc',
  //   remark: '!project.entryjs.remark',
  //   lineOfCode: 10000, // ~10,000
  //   images: ['image'],
  //   thumbnails: 'string',
  //   link: 'https://kenneth1003.github.io/entryjs/demo/performance_test.html',
  //   status: 'pending',
  //   tags: [
  //     // {
  //     //   name: 'Side project',
  //     //   type: TagType.Skill
  //     // },
  //     {
  //       name: 'Open source',
  //       type: TagType.Skill
  //     },
  //     {
  //       name: 'Javascript',
  //       type: TagType.Skill
  //     },
  //   ],
  // },

  {
    id: '3',
    slug: 'poker-memory-master',
    title: '!poker-memory-master.title',
    location: '!poker-memory-master.location',
    productName: '!poker-memory-master.productName',
    companyName: '!poker-memory-master.companyName',
    sideNote: '!poker-memory-master.sideNote',
    startDate: 'From 2017',
    endDate: '2019.06',
    description: [
      // '!poker-memory-master.desc1'
    ],
    // productDesc: '!poker-memory-master.productDesc',
    remark: '!poker-memory-master.remark',
    lineOfCode: 10000, // ~10,000
    images: ['image'],
    thumbnails: 'string',
    link: 'https://appadvice.com/app/poker-memory-master-lite/1326717094',
    linkCV: 'https://appadvice.com/app/poker-memory-master-lite/1326717094',
    status: 'pending',
    tags: [
      // {
      //   name: 'Side project',
      //   type: TagType.Skill
      // },
      {
        name: 'React Native',
        type: TagType.Skill
      },
      {
        name: 'Expo',
        type: TagType.Skill
      },
    ],
  },

  // {
  //   id: '6',
  //   slug: 'review-app',
  //   title: '!project.reviewapp.title',
  //   location: '!project.reviewapp.location',
  //   productName: '!project.reviewapp.productName',
  //   companyName: '!project.reviewapp.companyName',
  //   sideNote: '!project.reviewapp.sideNote',
  //   startDate: '2017.04',
  //   endDate: '2019.06',
  //   description: [
  //     // '!project.reviewapp.desc1'
  //   ],
  //   productDesc: '!project.reviewapp.productDesc',
  //   remark: '!project.reviewapp.remark',
  //   lineOfCode: 10000, // ~10,000
  //   images: ['image'],
  //   thumbnails: 'string',
  //   link: 'https://www.youtube.com/watch?v=Jm4Tb6XgbHo',
  //   status: 'pending',
  //   tags: [
  //     // {
  //     //   name: 'Side project',
  //     //   type: TagType.Skill
  //     // },
  //     {
  //       name: 'React Native',
  //       type: TagType.Skill
  //     },
  //   ],
  // },
];



export default projects;
