import Loadable from 'react-loadable';
import PageLoading from '../../components/common/PageLoading';

const HomeLoadable = Loadable({
  loader: () => import('./'),
  loading: PageLoading,
});


export default HomeLoadable;
