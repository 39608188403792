import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HomeLoadable from '../pages/Home/loadable';
import FunctionBar from '../widgets/FunctionBar';
// import ControlPanel from '../widgets/ControlPanel';
import LanguageProvider from '../widgets/LanguageProvider';
import PageContainer from '../components/common/PageContainer';
import PhilosophyLoadable from '../pages/Philosophy/loadable';

const Router = () => {
  return (
    <BrowserRouter>
      <LanguageProvider>
        <>
        <FunctionBar />
        <PageContainer>
          {/* <ControlPanel /> */}
          <Switch>
            {/* <Route
              path="/version"
              render={() => <VersionLoadable />}
              exact
            /> */}
            <Route
              path="/"
              render={() => <HomeLoadable />}
              exact
            />
            {/* <Route
              path="/zh"
              render={() => <HomeLoadable />}
              exact
            /> */}
            <Route
              path="/philosophy"
              render={() => <PhilosophyLoadable />}
              exact
            />
            <Route
              path="/zh/philosophy"
              render={() => <PhilosophyLoadable />}
              exact
            />
            <Route
              path="/zh/:section"
              render={() => <HomeLoadable />}
              exact
            />
            <Route
              path="/:section"
              render={() => <HomeLoadable />}
              // exact
            />
            
            <Route
              path="/skills"
              render={() => <HomeLoadable />}
              exact
            />

            <Route
              path="/:lang"
              render={() => <HomeLoadable />}
              // exact
            />
            <Route
              path="/:lang/skills"
              render={() => <HomeLoadable />}
              exact
            />
          </Switch>
        </PageContainer>
        </>
      </LanguageProvider>
    </BrowserRouter>
  );
};

export default Router;
