import { css } from 'styled-components';

export const PageSizing = css`
margin: 0 auto;
width: 900px;
@media screen and (max-width: 900px) {
  width: 100%;
}
@media print {
  width: 800px;
  padding: 0;
}
`;