import React from 'react';
import { IntlProvider, } from 'react-intl';
/* eslint-disable-next-line */
import { withRouter, RouteComponentProps, match } from 'react-router';

import en from '../../translation/en/index.json';
import zh from '../../translation/zh/index.json';

const messages: {
  [key:string]: {}
} = {
  en,
  zh
};

const LANGUAGES = [
  'en',
  'zh'
];

const DEFAULT_LANG = LANGUAGES[0];

export type PathParamsType = {
  lang: string,
}

type Props = RouteComponentProps<PathParamsType> & {
  children: React.ReactNode
}

export function getCurrentLang(match: match<PathParamsType>): string {
  if (match.params && LANGUAGES.indexOf(match.params.lang) !== -1) {
    return match.params.lang != null
      ? match.params.lang
      : DEFAULT_LANG;
  }
  return DEFAULT_LANG;
}

export function getCurrentLangByLocation(): string {
  if (window.location.pathname.slice(1, 3) === 'zh') {
    return 'zh';
  }
  return 'en';
}

export function fromUrl(url: string): string {
  if (getCurrentLangByLocation() === 'zh') {
    return url.replace('/zh', '');
  }
  return url;
}

export function toUrl(url: string): string {
  const lang = getCurrentLangByLocation();
  if (lang === 'zh') {
    return `/zh${url}`;
  }
  return url;
}



const LanguageProvider: React.FunctionComponent<Props> = ({
  children,
  match
}: Props) => {
  const locale = getCurrentLangByLocation();
  return <IntlProvider
    locale={ locale }
    messages={messages[locale]}
  >
    { children }
  </IntlProvider>;
};

export default withRouter(LanguageProvider);
