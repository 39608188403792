/**
 * Currently not a good way for global enum
 */

export enum SkillTag  {
  Frontend,
  Backend,
  Genera
}

export enum TagType {
  General,
  Skill
}
