import React, { useReducer }  from 'react';
import { ThemeProvider } from 'styled-components';

import Router from '../../router';
import { GlobalStyle } from '../../style/setup';
import { getSkillsSync, getProjectsSync, getEducationsSync, getWorkExpsSync } from '../../services/apis';
import { lightTheme, darkTheme } from '../../style/vars';

export enum AppActionType {
  TOGGLE_DARK_MODE = '0',
  UPDATE = '1',
  UPDATE_ALL_ENTITIES = '2',
}

type AppStateContextType = {
  isDarkMode: boolean,
  skills: Array<Skill>,
  educations: Array<Education>,
  projects: Array<Project>,
  workExps: Array<WorkExp>
}

let isDarkMode = window.localStorage.getItem('isDarkMode');
isDarkMode = isDarkMode ? isDarkMode : 'false';

const initialState = {
  isDarkMode: JSON.parse(isDarkMode),
  skills: getSkillsSync(),
  educations: getEducationsSync(),
  projects: getProjectsSync(),
  workExps: getWorkExpsSync()
};

// Context must initial before knowing actual shape, so do a workaround for typing
export const AppStateContext =
  React.createContext<GeneralStateContextType<Partial<AppStateContextType>>>({
    state: initialState,
    dispatcher: () => {}
  });


const reducer = (state = initialState, action: GeneralAction) => {
  switch (action.type) {
  case AppActionType.UPDATE_ALL_ENTITIES:
    return {
      ...state,
      ...action.payload,
    };
  case AppActionType.TOGGLE_DARK_MODE:
    const isDarkMode = !state.isDarkMode;
    window.localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
    return {
      ...state,
      isDarkMode,
    };
  }
  return state;
};

const App = () => {
  const [state, dispatcher] = useReducer(reducer, initialState);
  return (
  <AppStateContext.Provider value={ {
      dispatcher,
      state
    } }
  >
    <ThemeProvider
      // theme={lightTheme}
      theme={
        state.isDarkMode
         ? darkTheme
         : lightTheme
      }
    >
      <>
        <GlobalStyle />
        <Router />
      </>
    </ThemeProvider>
  </AppStateContext.Provider>
  );
};

export default App;
